import React, { Component } from 'react';
import { plainTranslate } from '../../common/translate';
import { connect } from 'react-redux';
import { Select, Switch, Input, Form, Space, Button } from 'antd';
import 'antd/lib/modal/style/index.css';
import { FormOutlined, DeleteOutlined, CloseSquareOutlined } from '@ant-design/icons';
import SlideModal from '../../common/SlideModal';
import Yamde from 'yamde';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import Popup from '../../common/popup';

const { Option } = Select;

class SectionContents extends Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            availableFields: [],
            showOptionForm: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleArrayChange = this.handleArrayChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleMultipleSelectChange = this.handleMultipleSelectChange.bind(this);
        this.showModal = this.showModal.bind(this);
        this.handleOk = this.handleOk.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleElementChange = this.handleElementChange.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleSwitchChange = this.handleSwitchChange.bind(this);
        this.addNewSubItem = this.addNewSubItem.bind(this);
    }

    componentDidMount() {
        this.update();
    }

    addNewSubItem() {
        let items = this.state.value && this.state.value.value ? this.state.value.value : [];
        let fields = this.state.availableFields[0].fields;
        let newItem = [];
        for (let i = 0; i < fields.length; i++) {
            newItem.push({});
            newItem[i]['id'] = fields[i].id;
            newItem[i]['value'] = '';

        }
        items.push(newItem);
        let elem = this.state.value;
        elem.value = items;
        this.setState({ value: elem });
    }

    chooseSection(type) {
        let availableFields = this.props.fields.filter((item) => item.id == type);

        return availableFields ? availableFields : null;
    }

    handleElementChange(value) {
        let section = this.state.value;
        section.id = value;
        let availableFields = this.chooseSection(value);
        this.setState({
            availableFields: availableFields,
            value: section,
        });
    }

    update() {
        let availableFields = this.chooseSection(this.props.item.id);
        this.setState({
            value: this.props.item ? this.props.item : [],
            availableFields: availableFields
        });
    }

    handleArrayChange(e, type, valKey, arrKey) {
        let value = this.state.value;
        value.value[valKey][arrKey]['value'] = e.target.value;

        this.setState({ value: value });
    }

    handleMultipleFieldChange(e, type, valKey) {
        let value = this.state.value;
        value.value[valKey]['type'] = e.target.value;
        this.setState({ value: value });
    }

    handleChange(e) {
        let value = this.state.value;
        value.value = e.target.value;

        this.setState({ value: value });
    }

    handleMultipleChange(e, type) {
        let value = this.state.value;

        let item = { id: type, value: e.target.value };
        if (!value.hasOwnProperty('value')) {
            value.value = [];
            value.value.push(item);
        } else {
            var index = value.value.findIndex(obj => obj.id === type);
            if (index > -1) {
                value.value[index] = item;
            } else {
                value.value.push(item);
            }

        }
        this.setState({ value: value });
    }

    markdownChange = (val) => {
        let value = this.state.value;
        value.value = val;

        this.setState({ value: value });
    }

    handleSelectChange(val) {
        let value = this.state.value;
        value.value = val;

        this.setState({ value: value });
    }

    handleMultipleSelectChange(val) {
        let value = this.state.value;
        let array = [];
        var i;
        for (i = 0; i < val.length; i++) {
            let newItem = {};
            newItem['value'] = val[i];
            newItem['label'] = this.state.availableFields[0].options.filter(item => item.value == val[i])[0].label;
            array.push(newItem);
        }
        value.value = array;
        this.setState({ value: value });
    }

    handleSelectMultipleSelectChange(val, item, key, type) {
        let value = this.state.value;
        if (type == 'deliveryMethod') {
            let selected = item.options.find((item) => item.value == val);
            value.value[key].value = selected.value;
            value.value[key].label = selected.label;
            value.value[key].rules = selected.rules;
            value.value[key].integration = selected.integration;
        }
        let arr = [];
        if (type == 'paymentMethods') {
            for (let i = 0; i < val.length; i++) {
                let selected = item.multipleOptions.find((item) => item.value == val[i]);
                arr.push(selected);
            }
            value.value[key][type] = arr;
        }
        if (type == 'shops') {
            for (let i = 0; i < val.length; i++) {
                let selected = item.multipleOptionsShops.find((item) => item.value == val[i]);
                arr.push(selected);
            }
            value.value[key][type] = arr;
        }
        this.setState({ value: value });
    }

    addDeliveryMethod() {
        let value = this.state.value;
        let method = {value: '', label: '', rules: [], paymentMethods: [], integration: []};
        if (!value.hasOwnProperty('value')) {
            value.value = [];
        }
        value.value.push(method);
        this.setState({ value: value });
    }

    deleteDeliveryMethod(ind) {
        let value = this.state.value;
    
        value.value.splice(ind, 1);
        this.setState({ value: value });
    }

    handleSwitchChange(checked) {
        let value = this.state.value;
        value.value = checked;

        this.setState({ value: value });
    }

    handleDelete(type, ind = null, subType = null, subInd = null) {
        let value = this.state.value;
        if (subInd !== null) {
            value[type][ind][subType].splice(subInd, 1);
        } else if (ind !== null && subInd == null) {
            value[type].splice(ind, 1);
        }

        this.setState({ value: value });
    }

    showModal() {
        this.setState({
            visible: true,
        });
    }

    handleOk() {
        this.props.change();
        this.setState({
            visible: false,
        });
    }

    handleCancel() {
        this.setState({
            visible: false,
        });
    };

    componentDidUpdate(prevProps) {
        if (prevProps.item != this.props.item) {
            this.setState({
                value: this.props.item
            })
        }
        if (this.formRef.current && this.state.showOptionForm) {
            var contacts = [];
            if (this.state.availableFields[0].id == 'contacts') {
                this.state.value && this.state.value.value && this.state.value.value.length && this.state.value.value.map(contact => {
                    contact.value = contact.value.toString()
                    contacts.push(contact)
                })
            }
            this.formRef.current.setFieldsValue({
                options: contacts
            });
        }

    }

    handleChangeNewOption = (values) => {
        let newStateValue = this.state.value;
        newStateValue.value = values.options;
        this.setState({ value: newStateValue });
        this.setState({ showOptionForm: false });
    }

    onSubmit = () => {
        this.formRef.current.submit()
    }

    render() {
        return (
            <div>
                {this.state.value &&
                    <div className="row">
                        <div className="accordion col-md-12 m-bot15">
                            <div className="accordion-group">
                                <div className="accordion-heading area collapsible col-md-12 no-gutter">
                                    <div className="col-md-9 col-xs-6">
                                        <strong>{this.state.value.id}</strong>
                                    </div>
                                    <div className="col-md-3 col-xs-6">
                                        <span style={{ marginRight: 10 }} onClick={this.showModal}><FormOutlined title={plainTranslate(this.props.locale, 'Edit menu item')} /> {plainTranslate(this.props.locale, 'Edit')}</span>
                                        <span onClick={() => this.props.remove(this.state.value)}><DeleteOutlined title={plainTranslate(this.props.locale, 'Remove menu item')} /> {plainTranslate(this.props.locale, 'Delete')}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.state.visible &&
                            <SlideModal
                                title={plainTranslate(this.props.locale, 'Edit item')}
                                onClose={this.handleCancel}
                            >
                                <section className="newPanel">
                                    <div className="panel-body">
                                        <div className="row m-bot15 form-group" >
                                            <label className="text-left">{plainTranslate(this.props.locale, 'Type element')}</label>
                                            <Select value={this.state.value.id} style={{ maxWidth: '100%', minWidth: "100%", marginBottom: '10px' }} onChange={(value) => this.handleElementChange(value)}>
                                                {this.props.fields && this.props.fields.length && this.props.fields.map((field, key) => {
                                                    return <Option key={key} value={field.id}>{field.id}</Option>
                                                })
                                                }
                                            </Select>
                                            {this.state.availableFields.length ? this.state.availableFields.map((item, key) => {
                                                return <><div className='col-md-12' key={key}>
                                                    {item.type !== 'array' && <label className="text-left">{plainTranslate(this.props.locale, item.id)}</label>}
                                                    {item.tooltip &&
                                                        <strong>  (*{item.tooltip})</strong>
                                                    }
                                                    {item.type == 'text' &&
                                                        <input type="text" className="form-control" onChange={(e) => this.handleChange(e)}
                                                            value={this.state.value.value ? this.state.value.value : null}
                                                        />
                                                    }
                                                    {item.type == 'textarea' &&
                                                        <textarea onChange={(e) => this.handleChange(e)} rows="4" cols="50" value={this.state.value.value ? this.state.value.value : null} />
                                                    }
                                                    {item.type == 'markdown' &&
                                                        <Yamde value={this.state.value.value ? this.state.value.value : null} handler={this.markdownChange} theme="light" />
                                                    }
                                                    {item.type == 'switch' &&
                                                        <Switch style={{ marginLeft: '30px' }} checked={this.state.value.value ? this.state.value.value : null} onChange={this.handleSwitchChange} />
                                                    }
                                                    {item.type == 'multipleSelect' &&
                                                        <Select mode="multiple" value={this.state.value && this.state.value.value && this.state.value.value.length && this.state.value.value.map(({ value }) => value)} style={{ maxWidth: '100%', minWidth: "100%", marginBottom: '10px' }} onChange={this.handleMultipleSelectChange}>
                                                            {item.options && item.options.length && item.options.map((option, key) => {
                                                                return <Option key={key} value={option.value}>{option.label}</Option>
                                                            })
                                                            }
                                                        </Select>
                                                    }
                                                    {item.type == 'select' &&
                                                        <Select value={this.state.value.value ? this.state.value.value : null} style={{ maxWidth: '100%', minWidth: "100%", marginBottom: '10px' }} onChange={this.handleSelectChange}>
                                                            {item.options && item.options.length && item.options.map((option, key) => {
                                                                return <Option key={key} value={option.value}>{option.label}</Option>
                                                            })
                                                            }
                                                        </Select>
                                                    }
                                                    {item.type == 'select-multipleSelect' &&
                                                        <>
                                                            {this.state.value?.value && this.state.value.value.map((method, ind) => {
                                                                return <div key={ind}>
                                                                    <Select value={method.value} style={{ maxWidth: '90%', minWidth: "90%", marginBottom: '10px' }} onChange={(val) => this.handleSelectMultipleSelectChange(val, item, ind, 'deliveryMethod')}>
                                                                        {item.options && item.options.length && item.options.map((option, key) => {
                                                                            return <Option key={key} value={option.value}>{option.label}</Option>
                                                                            })
                                                                        }
                                                                    </Select>
                                                                    <CloseSquareOutlined title={plainTranslate(this.props.locale, 'Delete delivery method')} onClick={() => this.deleteDeliveryMethod(ind)} style={{marginLeft:'20px'}}/>
                                                                    <Select mode="multiple" defaultValue={method && method?.paymentMethods.length>0 ? method.paymentMethods.map((val) => val ? val.value : null) : null} style={{ maxWidth: '90%', minWidth: "90%", marginBottom: '10px' }} onChange={(val) => this.handleSelectMultipleSelectChange(val, item, ind, 'paymentMethods')}>
                                                                        {item.multipleOptions && item.multipleOptions.length && item.multipleOptions.map((option, key) => {
                                                                            return <Option key={key} value={option.value}>{option.label}</Option>
                                                                            })
                                                                        }
                                                                    </Select>
                                                                    <Select mode="multiple" defaultValue={method && method?.shops?.length>0 ? method.shops.map((val) => val ? val.value : null) : null} style={{ maxWidth: '90%', minWidth: "90%", marginBottom: '10px' }} onChange={(val) => this.handleSelectMultipleSelectChange(val, item, ind, 'shops')}>
                                                                        {item.multipleOptionsShops && item.multipleOptionsShops.length && item.multipleOptionsShops.map((option, key) => {
                                                                            return <Option key={key} value={option.value}>{option.label}</Option>
                                                                            })
                                                                        }
                                                                    </Select>
                                                                </div>
                                                            })
                                                            }
                                                            <Button className="button-outline grey" onClick={() => this.addDeliveryMethod()} >
                                                                {plainTranslate(this.props.locale, 'Add delivery method')}
                                                            </Button>
                                                        </>
                                                    }
                                                    {item.type == 'multipleField' && !item.addOption && item.fields && item.fields.length && item.fields.map((field, inf) => {
                                                        return <div className='col-md-12' key={inf}>
                                                            <label className="text-left">{plainTranslate(this.props.locale, field.id)}</label>
                                                            <input type="text" className="form-control" onChange={(e) => this.handleMultipleChange(e, field.id, inf)}
                                                                value = {this.state.value && this.state.value.value && this.state.value.value.length && this.state.value.value.find(item => item.id === field.id) ? this.state.value.value.find(item => item.id === field.id)['value'] : null}
                                                            />
                                                        </div>
                                                        })    
                                                    } 
                                                    {item.type == 'multipleField' && item.addOption && this.state.value.id == item.id && this.state.value && this.state.value.value && this.state.value.value.map((field, inf) => {
                                                        return <div className='col-md-12' key={inf}>
                                                            <label className="text-left">{plainTranslate(this.props.locale, field.id)}</label>
                                                            <input type="text" className="form-control" onChange={(e) => this.handleMultipleChange(e, field.id, inf)}
                                                                value={field.value}
                                                            />
                                                        </div>
                                                    })
                                                    }
                                                    {item.addOption &&
                                                        <Button className="button-outline grey" onClick={() => this.setState({ showOptionForm: true })} >
                                                            {plainTranslate(this.props.locale, 'Add option')}
                                                        </Button>
                                                    }
                                                    {this.state.showOptionForm &&
                                                        <Popup onClose={() => this.setState({ showOptionForm: false })} title={plainTranslate(this.props.locale, 'Add options')}>
                                                            <Form name="dynamic_form_nest_item" ref={this.formRef}
                                                                onFinish={this.handleChangeNewOption} autoComplete="off" id="content-field">
                                                                <Form.List name="options">
                                                                    {(fields, { add, remove }) => (
                                                                        <>
                                                                            {fields.map(({ key, name, ...restField }) => (
                                                                                <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                                                                    <Form.Item
                                                                                        {...restField}
                                                                                        name={[name, 'id']}
                                                                                        rules={[{ required: true, message: 'Missing value' }]}
                                                                                    >
                                                                                        <Input placeholder="Contact type" />
                                                                                    </Form.Item>
                                                                                    <Form.Item
                                                                                        {...restField}
                                                                                        name={[name, 'value']}
                                                                                        rules={[{ required: true, message: 'Missing label' }]}
                                                                                    >
                                                                                        <Input placeholder="Value" />
                                                                                    </Form.Item>
                                                                                    <MinusCircleOutlined onClick={() => remove(name)} />
                                                                                </Space>
                                                                            ))}
                                                                            <Form.Item>
                                                                                <Button
                                                                                    htmlType="button"
                                                                                    className='button-outline green'
                                                                                    onClick={this.onSubmit} >
                                                                                    {plainTranslate(this.props.locale, 'Save to options')}
                                                                                </Button>

                                                                                <Button type="dashed" className='button-outline grey' onClick={() => add()} icon={<PlusOutlined />}>
                                                                                    {plainTranslate(this.props.locale, 'Add option')}
                                                                                </Button>
                                                                            </Form.Item>
                                                                        </>
                                                                    )}
                                                                </Form.List>
                                                            </Form>
                                                        </Popup>
                                                    }
                                                    {item.type == 'array' && this.state.value && this.state.value.value && this.state.value.value.length && this.state.value.value.map((sval, n) => {
                                                        return (<><p>{n + 1}</p>
                                                            {sval.map((sub, ind) => {
                                                                return <div className='col-md-12' key={ind}>
                                                                    <label className="text-left">{plainTranslate(this.props.locale, sub.id)}</label>
                                                                    <input type="text" className="form-control" onChange={(e) => this.handleArrayChange(e, sub.id, n, ind)}
                                                                        value={sub.value}
                                                                    />
                                                                </div>
                                                            })
                                                            }
                                                        </>
                                                        )
                                                    })}
                                                    {item.type == 'array' &&
                                                        <a className="button-outline grey" onClick={this.addNewSubItem}>{plainTranslate(this.props.locale, 'Add new')}</a>
                                                    }
                                                </div>
                                                </>
                                            })
                                                : <p>No fields added for this element</p>
                                            }
                                            <div style={{ float: 'right' }}>
                                                <a className="button-outline grey" onClick={this.handleCancel}>{plainTranslate(this.props.locale, 'Cancel')}</a>
                                                <a className="button-outline green" onClick={this.handleOk}>{plainTranslate(this.props.locale, 'Save')}</a>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </SlideModal>
                        }
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(SectionContents);
