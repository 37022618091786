import React from 'react';
import { connect } from 'react-redux';
import client from '../../actions/client'
import {openViewHandler} from '../../actions/actions'
import { EyeOutlined } from '@ant-design/icons'


const ViewRenderer = (props) => {
      const handleOpen = () => {
        client
            .get(props.url + props.value)
            .then(response => {
                props.openViewHandler(true, response.data.value)
            })
            .catch(error => {

            })
    }

    return (<div className="text-center"> 
        <i className="ion-eye" onClick={handleOpen}></i>
       </div>);
}

const mapStateToProps = state => ({
    settings: state.settings
});


export default connect(mapStateToProps, {openViewHandler} )(ViewRenderer);