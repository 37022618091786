import { Button, Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import AsyncSelect from 'react-select/lib/Async';
import client from '../../../actions/client';
import SlideModal from '../../common/SlideModal';
import { plainTranslate } from '../../common/translate';
import ReduxForm from '../form';
import Container from './container';

const SelectSection = props => {
    const [show, setShow] = useState(false);
    const [containerModal, setContainerModal] = useState(false);
    const [options, setOptions] = useState([]);
    const [value, setValue] = useState([]);
    const [showContent, setShowContent] = useState(false);
    const [container, setContainer] = useState(null);
    const [containerKey, setContainerKey] = useState(null);
    const [componentId, setComponentId] = useState(null);
    const formRef = React.createRef();
    const [form] = Form.useForm();

    useEffect(() => {
        setOptions(loadOptions(null));
        setValue(props.input.value ? JSON.parse(props.input.value).sections : []);
    }, [])

    useEffect(() => {
        if (props.db !== null && !options) {
            setOptions(loadOptions(null));
        }
    }, [props.db])

    const loadOptions = (inputValue, callback) => {
        client
            .get('/' + props.db + '/api/search-components?q=' + inputValue)
            .then(response => response.data)
            .then(response => {
                let collOptions = [];
                if (response) {
                    collOptions = response.map(option => {
                        return {
                            ...option,
                            'value': option.value,
                            'label': option.label
                        };
                    });
                    setOptions(collOptions);
                }
                if (callback) return callback(collOptions ? collOptions : options);
            });
    }

    const onChange = (data) => {
        let sections = data.hasOwnProperty('data') && data.data ? JSON.parse(data.data) : {};
        
        let val = value ? value : [];
        sections.sections.id = data.id;
        sections.sections.componentId = data.value;
        sections.sections.componentCode = data.cc;  
        let valContainer = container;
        
        valContainer.sections.push(sections.sections);
        setValue(val);
        change();
        setShow(false);
        setContainerKey(null);
        setContainer(null);
    }

    const onChangeForm = (data) => {
        let val = value ? value : [];
        console.log(data, componentId, value)
        let valContainer;
        if (componentId && containerKey != null) {
            let sections = data.entity.contentData; 
            if (sections) {
                sections.sections.id = data.entity.type;
                sections.sections.componentId = componentId;
                sections.sections.componentCode = data.entity.cc;
                valContainer = container;
                valContainer.sections[containerKey] = sections.sections;    
                console.log(valContainer)
            }   
        } 
        else {
            let sections = data.entity.contentData; 
            sections.sections.id = data.entity.type;
            sections.sections.componentId = data.entity.id;
            sections.sections.componentCode =  data.entity.cc
            valContainer = container;
            valContainer.sections.push(sections.sections);
            console.log(valContainer)
        }
        
        setValue(val);
        change();
        setShow(false);
        setContainerKey(null);
        setContainer(null);
        setComponentId(null);
    }

    const onDragEnd = (val) => {
        let items = value;
        let droppable = val.destination.droppableId;
        let destinationIndex = val.destination.index;
        let sourceIndex = val.source.index;
        let updatedItems = null;

        if (droppable == 'main') {
            updatedItems = moveInArray(items, sourceIndex, destinationIndex);
        } else {
            let index = null;
            let updatedSubItems = null;
            items.find((item, key) => {
                if (item.name === droppable) {
                    index = key;
                }
            });

            if (index !== null) {
                updatedSubItems = moveInArray(items[index][props.field.settings.subLevel], sourceIndex, destinationIndex);
                items[index][props.field.settings.subLevel] = updatedSubItems;
                updatedItems = items;
            } else {
                updatedItems = items;
            }
        }
        setValue(updatedItems);
        // change();
    };

    const moveInArray = (arr, from, to) => {
        if (Object.prototype.toString.call(arr) !== '[object Array]') {
            throw new Error('Please provide a valid array');
        }
        var item = arr.splice(from, 1);
        if (!item.length) {
            throw new Error('There is no item in the array at index ' + from);
        }
        arr.splice(to, 0, item[0]);
        return arr;
    };

    const change = () => {
        let menu = new Object();
        menu['sections'] = value;
        props.input.onChange(JSON.stringify(menu));
    }

    const remove = (parent, item) => {
        let index;
        if (parent) {
            index = parent.sections.findIndex(e => e.name === item.name);
            if (index == -1) {
                index = parent.sections.findIndex(e => e.title === item.title);
            }
            if (index != -1) {
                parent.sections.splice(index, 1);   
            } 
        } else {
            const index = value.findIndex(e => e.name === item.name)
            if (index != -1) {
                value.splice(index, 1);   
            } 
        }
        change();
    }

    const editSection = (parent, item, key) => {
        setContainerKey(key);
        setComponentId(item.componentId);
        setContainer(parent);
        setShow(true);
        //change();
    }

    const showNewPopup = () => {
        return (
            <SlideModal onClose={() => setShow(false)} title={plainTranslate(props.locale, 'Sections')}>
                <section className="newPanel">
                    <div className="panel-body">
                        <div className="row">
                            <div className="col-md-1"></div>
                            {!componentId &&
                                <div className="col-md-10 col-xs-12">
                                    <h4 className="no-gutter">{plainTranslate(props.locale, 'Add from existing sections')}</h4>
                                    <AsyncSelect
                                        isClearable
                                        cacheOptions
                                        isSearchable
                                        onChange={(value) => onChange(value)}
                                        onBlurResetsInput={false}
                                        onBlur={event => event.preventDefault()}
                                        onCloseResetsInput={false}
                                        menuPlacement={"auto"}
                                        loadOptions={loadOptions}
                                        defaultOptions={options}
                                    />
                                </div>
                            }
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-xs-12 no-gutter">
                                <div className="col-md-1"></div>
                                <div className="col-md-10 col-xs-12">
                                    <hr />
                                    {componentId ?
                                        <h4 className="no-gutter">{plainTranslate(props.locale, 'Edit component')}</h4>
                                        :
                                        <h4 className="no-gutter">{plainTranslate(props.locale, 'Or create new section')}</h4>
                                    }
                                </div>
                                <ReduxForm
                                    formRoute={props.db + '/api/cms-components/'}
                                    saveRoute={componentId ? `${props.db}/api/cms-components/` : `${props.db}/api/cms-components`}
                                    popup={true}
                                    isNew={true}
                                    onSuccessfulSave={(data) => onChangeForm(data)}
                                    match={{ params: { id: componentId ? componentId : 'createNew' } }}
                                    form={'initializeFromState'}
                                />

                            </div>
                        </div>
                    </div>
                </section>
            </SlideModal>
        );
    }
    const onFinish = (values) => {
        let val = value ? value : [];
        let valContainer = container;
        if (container != null && containerKey != null) {
            let cont = { id: 'container' + '-' + val.length + 1, type: 'container', className: values.className, name: values.name, key: val.length + 1, sections: [] }
            if (containerModal == 'edit') {
                valContainer.name = values.name;
                valContainer.className = values.className;
            } else {
                if (containerModal == 'edit') {
                    valContainer.name = values.name;
                    valContainer.className = values.className;
                } else {
                    valContainer.sections.push(cont);
                } 
            }
        } else {
            let container = { id: 'container' + '-' + val.length + 1, type: 'container', className: values.className, name: values.name, key: val.length + 1, sections: [] }
            val.push(container);
        }
        setValue(val);
        change();
        setContainerModal(false);
        setContainerKey(null);
        setContainer(null);
    }

    const onSubmit = () => {
        formRef.current.submit();
    }

    const addSection = (key, item) => {
        setContainerKey(key);
        setComponentId(null);
        setShow(true);
        setContainer(item);
    }

    const editContainer = (key, item) => {
        console.log(key, item)
        setContainerModal('edit');
        setContainerKey(key);
        setContainer(item);
        form.setFieldsValue(item);
    }

    const handleShowContent = (itemId) => {
        if (showContent) {
            setShowContent(false);
        } else {
            setShowContent(itemId);
        }  
    }

    const addContainer = () => {
        form.setFieldsValue({ name: '', className: '' });
        setContainerModal(true);
    }

    const addInnerContainer = (key, item) => {
        form.setFieldsValue({ name: '', className: '' });
        setContainerKey(key);
        setContainerModal(true);
        setContainer(item)
    }

    return (
        <>
            <div>
                <React.Fragment>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="main">
                            {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {value && value.length > 0 && value.map((item, ind) =>
                                        <Draggable key={item.id} draggableId={item.id} index={ind}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <Container 
                                                        item={item}
                                                        parent={null}
                                                        key={ind}
                                                        type={item.type == "container" ? "container" : "section"}
                                                        containerId={ind}
                                                        remove={(parent, item) => remove(parent, item)}
                                                        addSection={(ind, item) => addSection(ind, item)}
                                                        addInnerContainer={(ind, item) => addInnerContainer(ind, item)}
                                                        editContainer={(ind, item) => editContainer(ind, item)}
                                                        editSection={(parent, item, ind) => editSection(parent, item, ind)}
                                                        handleShowContent={(itemId) => handleShowContent(itemId)}
                                                        showContent={showContent}
                                                    />
                                                </div>
                                            )}
                                        </Draggable>
                                    )}
                                    <a className="button-outline grey" onClick={() => addContainer()}>{plainTranslate(props.locale, 'Add container')}</a>
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </React.Fragment>
            </div>

            {show && showNewPopup()}
            {containerModal &&
                <SlideModal onClose={() => setContainerModal(false)} title={plainTranslate(props.locale, 'Add container')}>
                    <section className="newPanel">
                        <div className="panel-body">
                            <Form form={form} name="form_content_field" ref={formRef}
                                onFinish={onFinish} autoComplete="off" id="content-field">
                                <h4>{plainTranslate(props.locale, 'Container')}</h4>
                                <Form.Item
                                    name='name'
                                    rules={[{ required: true, message: 'Missing value' }]}
                                >
                                    <Input placeholder="Name" />
                                </Form.Item>
                                <Form.Item
                                    name='className'
                                >
                                    <Input placeholder="Class" />
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        htmlType="button"
                                        onClick={onSubmit} className="button-primary">
                                        {plainTranslate(props.locale, "Save")}
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </section>
                </SlideModal>
            }
        </>
    )
};

export default SelectSection;